import React from "react";
import Independencia from "../images/Independencia.jpeg";
import Resultado from "../images/Resultados.jpeg";
import Compromiso from "../images/Compromiso.jpeg";
import Transparencia from "../images/Transparencia.jpeg";
import { useTranslation } from "react-i18next";
import "../../src/index.css";
const Valores = () => {
  const [t] = useTranslation("global");

  return (
    <div id="valores" className="text-white bg-[#6B94AE] md:flex-col">
      <div className="flex flex-col items-center justify-center mt-14">
        <h2 className="font-bold text-center text-xl sm:text-2xl md:text-3xl lg:text-4xl  xl:text-5xl px-10 pt-10 pb-6">
          {t("traduccion.tituloNosotros")}
        </h2>
        <p className="text-center font-semibold text-sm md:text-base lg:text-lg xl:text-xl">
          {t("traduccion.nosotros")}
        </p>
      </div>

      <div className="mt-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        <div className="flex flex-col items-center p-4">
          <img className="h-60 w-80 object-cover mb-4" src={Resultado} alt="resultados" />
          <h3 className="font-bold text-lg md:text-2xl lg:text-3xl xl:text-4xl">
            {t("traduccion.tituloResultado")}
          </h3>
          <p className="text-center  mt-2  font-light text-sm md:text-base lg:text-lg xl:text-xl">
            {t("traduccion.resultado")}
          </p>
        </div>

        <div className="flex flex-col items-center p-4">
          <img className="h-60 w-80 object-cover mb-4" src={Compromiso} alt="compromiso" />
          <h3 className="font-bold text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl">
            {t("traduccion.tituloCompromiso")}
          </h3>
          <p className="text-center  mt-2 font-light   text-sm md:text-base lg:text-lg xl:text-xl">
            {t("traduccion.compromiso")}
          </p>
        </div>

        <div className="flex flex-col items-center p-4">
          <img className="h-60 w-80 object-cover mb-4" src={Independencia} alt="independencia" />
          <h3 className="font-bold text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl">
            {t("traduccion.tituloIndependencia")}
          </h3>
          <p className="text-center mt-2 font-light   text-sm md:text-base lg:text-lg xl:text-xl">
            {t("traduccion.independencia")}
          </p>
        </div>

        <div className="flex flex-col items-center p-4 mb-8">
          <img className="h-60 w-80 object-cover mb-4" src={Transparencia} alt="transparencia" />
          <h3 className="font-bold text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl">
            {t("traduccion.tituloTransparencia")}
          </h3>
          <p className="text-center mt-2 font-light   text-sm md:text-base lg:text-lg xl:text-xl">
            {t("traduccion.transparencia")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Valores;
