import React from "react";
import { useTranslation } from "react-i18next";
import AsesorFinanciero from "../images/Asesoramientofinanciero.jpeg";
import GestionInmobiliaria from "../images/Gestion inmobiliaria.jpeg";
import ControlInformes from "../images/Control e informes.jpeg";
import PlanificacionFamiliar from "../images/Planificacion.jpeg";
import "../../src/index.css";

const QueHacemos = () => {
  const [t] = useTranslation("global");

  // Define a Tailwind CSS class for the image container
  const imageContainerClass =
    "m-4 text-center transform hover:scale-105 transition-transform duration-300 ease-in-out";

  // Define a Tailwind CSS class for the image itself
  const imageClass = "h-36 sm:h-48 w-80 object-cover";

  return (
    <>
      <div
        id="quehacemos"
        className="flex flex-col items-center justify-center mt-8 px-6 md:mt-20"
      >
        <h2 className="font-bold  text-[#004774] sm:text-[#004774] md:text-[#004774] lg:text-[#004774] xl:text-[#004774] text-2xl titulos sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl p-5 md:p-10">
          {t("traduccion.tituloQueHacemos")}
        </h2>
        <p className="text-center  text-sm md:text-base lg:text-lg xl:text-xl">
          {t("traduccion.queHacemos")}
        </p>
      </div>

      <div className="mt-4 grid gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4">
        <div className={imageContainerClass}>
          <div className="flex flex-col justify-center items-center">
            <img
              className={imageClass}
              src={AsesorFinanciero}
              alt="asesoramientoFinanciero"
            />
            <h2 className="font-bold pt-4 text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl">
              {t("traduccion.tituloAsesor")}
            </h2>
            <p className="text-center pt-2 text-sm md:text-base lg:text-lg xl:text-xl">
              {t("traduccion.asesoraFinanciero")}
            </p>
          </div>
        </div>

        <div className={imageContainerClass}>
          <div className="flex flex-col justify-center items-center">
            <img
              className={imageClass}
              src={GestionInmobiliaria}
              alt="gestionInmobiliaria"
            />
            <h2 className="font-bold pt-4 text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl">
              {t("traduccion.tituloInmobiliaria")}
            </h2>
            <p className="text-center pt-2 text-sm md:text-base lg:text-lg xl:text-xl">
              {t("traduccion.gestionInmobiliria")}
            </p>
          </div>
        </div>

        <div className={imageContainerClass}>
          <div className="flex flex-col justify-center items-center">
            <img
              className={imageClass}
              src={ControlInformes}
              alt="controlInformes"
            />
            <h2 className="font-bold pt-4 text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl">
              {t("traduccion.tituloControlInf")}
            </h2>
            <p className="text-center pt-2 text-sm md:text-base lg:text-lg xl:text-xl">
              {t("traduccion.controlEinformes")}
            </p>
          </div>
        </div>

        <div className={imageContainerClass}>
          <div className="flex flex-col justify-center items-center">
            <img
              className={imageClass}
              src={PlanificacionFamiliar}
              alt="planificacionFamiliar"
            />
            <h2 className="font-bold pt-4 text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl">
              {t("traduccion.tituloPlanificar")}
            </h2>
            <p className="text-center pt-2 text-sm md:text-base lg:text-lg xl:text-xl">
              {t("traduccion.planificacionfamiliar")}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default QueHacemos;
