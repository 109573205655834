import "../../src/index.css";

import NotaJulio from "../images/notaJulio.png";
import NotaAgosto from "../images/notaAgost.png";
import NotaSeptiembre15 from "../images/notaSeptiembre15.png";
import NotaSeptiembre20 from "../images/septiembre.png";

import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Navigation } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";


const Nota = ({ href, imgSrc, buttonText }) => {
  return (
    <div className="rounded-t-lg">
      <img src={imgSrc} alt="img"/>
      <div className="mb-12 bg-slate-300 text-[#537b93] w-[340px] sm:w-[500px] md:w-[500px] font-semibold p-4 rounded-b-lg flex items-center justify-center m-auto flex-col">
        <a href={href} target="_blank" rel="noopener noreferrer" className="block w-full h-full">
          <button className="text-center sm:text-lg md:text-xl lg:text-2xl xl:text-3xl">
            {buttonText}
          </button>
        </a>
      </div>
    </div>
  );
};


// ... (código restante)

const Notas = () => {
  const { t } = useTranslation("global");

  const notasData = [
    {
      href: "/notas-de-julio.pdf",
      imgSrc: NotaJulio,

      buttonText: t("traduccion.clickNota"),
    },
    {
      href: "/notas-de-agosto.pdf",
      imgSrc: NotaAgosto,

      buttonText: t("traduccion.clickNota"),
    },
    {
      href: "/notas-de-septiembre.pdf",
      imgSrc: NotaSeptiembre15,

      buttonText: t("traduccion.clickNota"),
    },
    {
      href: "https://www.cronista.com/finanzas-mercados/efecto-powell-pausa-las-acciones-globales-como-invertir-ahora/",
      imgSrc: NotaSeptiembre20,

      buttonText: t("traduccion.clickNota"),
    },
  ];

  return (
    <>
      <div
        id="notas"
        className="flex pt-8 flex-col pb-16 text-white justify-center items-center bg-[#6B94AE] mt-10"
      >
        <h2 className="font-bold text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl mb-8 flex items-center">
          {t("traduccion.tituloNotas")}
        </h2>
        <Swiper navigation={true} modules={[Navigation]} className="mySwiper auto">
          {notasData.map((nota, index) => (
            <SwiperSlide className="swiper-slide" key={index}>
              <Nota {...nota} t={t} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export default Notas;
