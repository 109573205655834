import React from "react";
import { useTranslation } from "react-i18next";
import "../../src/index.css";

import Mision from "./Mision";
import QueHacemos from "./QueHacemos";
import Valores from "./Valores";
import Navbar from "./Navbar";
import Notas from "./Notas";
import Footer from "./Footer";



const Main = () => {
  const [t] = useTranslation("global");
  return (
    <>
     <Navbar />
      <div className="w-full relative">
     
      
        <img className=" h-full w-full filter brightness-[.7] object-cover"
          src="/bg-image.jpg"
            alt="fotoPortada"
          />
         
         <h2 className="text-white font-bold sm:text-xl md:text-4xl absolute top-[50%] left-[50%] text-center transform -translate-x-[50%] -translate-y-[50%]">
 
          
          {t("traduccion.titleAcompanamos")}
        </h2>
      </div>
      <Mision />
      <Valores />
      <QueHacemos />

      <Notas />

      <Footer/>
    </>
  );
};

export default Main;


