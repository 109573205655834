import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next"

import "./index.css";
 import Main from './components/Main'

import global_es from "./translation/espanol/globalSpanish.json";
import global_en from "./translation/ingles/globalEnglish.json";
;



const urlParams = new URLSearchParams(window.location.search);
const lang = urlParams.get('lang');
console.log(lang)
i18next.init({
  interpolation: { escapeValue: false },
  lng: lang || 'es', // Default language is 'es' if lang is not provided in the URL
  resources: {
    es: {
      global: global_es,
    },
    en: {
      global: global_en,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <I18nextProvider i18n={i18next}>
   
        <Main />
      </I18nextProvider>
    </Router>
  </React.StrictMode>
);






