import React from "react";
import "../../src/index.css";
import { useTranslation } from "react-i18next";

const Mision = () => {
  const [t] = useTranslation("global");
  return (
    <div id="mision" className="flex flex-col items-center justify-center mt-8 ">
      <h2 className="font-bold  text-[#004774] sm:text-[#004774] md:text-[#004774] lg:text-[#004774] xl:text-[#004774] mb-4 text-xl sm:text-2xl md:text-3xl lg:text-4xl  xl:text-5xl ">
        {t("traduccion.tituloMision")}
      </h2>

      <p className="text-center text-[#004774]  misiontexto px-4 text-sm md:text-base lg:text-lg xl:text-xl  w-[78%]">
        {t("traduccion.mision")}
      </p>
    </div>
  );
};

export default Mision;
