import React, { useState } from "react";
import { Turn as Hamburger } from "hamburger-react";
import LogoCai from "../images/logoCAI.png";
import { useTranslation } from "react-i18next";

const Navbar = () => {
  const { t, i18n } = useTranslation("global");
  const [isOpen, setOpen] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState("en");

  const toggleMenu = () => {
    setOpen(!isOpen);
  };

  const handleLanguage = (language) => {
    setCurrentLanguage(language);
    const urlParams = new URLSearchParams(window.location.search);

    // Establecer el valor de un parámetro existente o agregar uno nuevo
    urlParams.set("lang", language);

    // Obtener la URL actual con los parámetros actualizados
    const nuevaURL = window.location.pathname + "?" + urlParams.toString();

    // Redirigir a la nueva URL
    window.location.href = nuevaURL;
  };

  return (
    <div id="home" className="w-full p-4 bg-[#004774]">
      <div className="flex items-center justify-between">
        <img
          className="logoCai"
          height={160}
          width={160}
          src={LogoCai}
          alt="logo"
        />

        <ul className="hidden text-[white] h-fit md:flex items-center justify-center gap-5 font-bold text-lg mr-4">
          <li className="hover:text-xl duration-300 ease-in-out text-center">
            <a href="#home">CAI</a>
          </li>
          <li className="hover:text-xl duration-300 ease-in-out">
            <a href="#mision">{t("traduccion.navMision")}</a>
          </li>
          <li className="hover:text-xl duration-300 ease-in-out">
            <a href="#quehacemos">{t("traduccion.navHacemos")}</a>
          </li>

          <li className="hover:text-xl duration-300 ease-in-out">
            <a href="#valores">{t("traduccion.navValores")}</a>
          </li>
          <li className="hover:text-xl duration-300 ease-in-out">
            <a href="#notas">{t("traduccion.navNotas")}</a>
          </li>
          <li className="hover:text-xl duration-300 ease-in-out">
            <a href="#contacto">{t("traduccion.navContacto")}</a>
          </li>
          <li className="hover:text-xl duration-300 ease-in-out">
            <button onClick={() => handleLanguage("es")}>
              <img
                src="https://flagcdn.com/es.svg"
                alt="Spain"
                width={25}
                className="h-4 object-cover"
              />
            </button>
          </li>
          <li className="hover:text-xl duration-300 ease-in-out">
            <button onClick={() => handleLanguage("en")}>
              <img
                src="https://flagcdn.com/us.svg"
                width={25}
                alt="United States"
                className="h-4 object-cover"
              />
            </button>
          </li>
        </ul>

        <div className="md:hidden block">
          <Hamburger
            toggled={isOpen}
            color="white"
            toggle={toggleMenu}
            size={28}
          />
        </div>
      </div>

      <div className={`${isOpen ? "block" : "hidden"} md:hidden mt-4`}>
        <ul className="text-[white] flex justify-start items-center flex-col font-bold text-2xl h-[100vh] p-4">
          <li className="mb-5 border-b-2 border-white text-center">
            <a href="#home">CAI</a>
          </li>
          <li className="mb-5 border-b-2 border-white">
            <a href="#mision">{t("traduccion.navMision")}</a>
          </li>

          <li className="mb-5 border-b-2 border-white">
            <a href="#quehacemos">{t("traduccion.navHacemos")}</a>
          </li>
          <li className="mb-5 border-b-2 border-white">
            <a href="#valores">{t("traduccion.navValores")}</a>
          </li>
          <li className="mb-5 border-b-2 border-white">
            <a href="#notas">{t("traduccion.navNotas")}</a>
          </li>
          <li className="mb-5 border-b-2 border-white">
            <a href="#contacto">{t("traduccion.navContacto")}</a>
          </li>
          <li className="mb-2 border-b-2">
          <button onClick={() => i18n.changeLanguage("es")}>
              <img
                src="https://flagcdn.com/es.svg"     
                alt="Spanish"
                width={25}
                className="h-4 object-cover"
              />
            </button>
          </li>
          <li className="mb-5 border-b-2">
          <button onClick={() => i18n.changeLanguage("en")}>
              <img
                src="https://flagcdn.com/us.svg"
                width={25}
                alt="United States"
                className="h-4 object-cover"
              />
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
